[data-view="events"] {
  .events {
    list-style: none;
    margin: 0;
    padding: 0 7.5px;

    .events-item {
      margin-bottom: 15px;
      padding-left: 7.5px;
      padding-right: 7.5px;
    }

    .ratio-helper {
      @include keep-ratio(1);
    }

    .item {
      display: block;

      .item-info {
        background-color: rgba(255, 255, 255, 0.85);
        font-size: .8em;
        padding: 7px 10px;
        text-align: center;
        width: 100%;

        h3 {
          font-size: 1.1em;
          margin-bottom: .4em;
        }
      }
    }
  }
}
