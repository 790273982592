a {
  text-decoration: none !important;
  color: $colorSubtle;

  &:hover {
    color: $colorSubtle;
  }
}

button {
  cursor: pointer;
}
