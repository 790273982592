[data-view="collection"] {
  .collection {
    list-style: none;
    margin: 0;
    padding: 0 7.5px;

    .collection-item {
      margin-bottom: 15px;
      padding-left: 7.5px;
      padding-right: 7.5px;
    }

    .ratio-helper {
      @include keep-ratio(1);
    }

    .item {
      box-shadow: 0 1px 5px rgba(0, 0, 0, .3);
      display: block;
    }
  }

  .gallery {
    .item-info {
      flex: 0 0 auto;
      font-size: .8em;
      opacity: 0;
      padding: 12px 0;
      transition: opacity 200ms 100ms;

      .galleryCard {
        color: $colorSubtle;
        position: relative;

        h4 {
          color: $colorFg;
          font-size: 1.2em;
          margin-bottom: .2em;
          word-break: break-all;
        }

        .sold {
          float: right;
          color: inherit;

          &.legend-visible {
            .sold-legend {
              opacity: 1;
            }
          }

          .sold-dot {
            color: $colorSold;
            padding: 0 5px;
          }

          .sold-legend {
            font-size: .8em;
            opacity: 0;
            transition: opacity 200ms;
            transform: translateX(-10px);
          }
        }
      }
    }
  }
}
