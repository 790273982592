@mixin keep-ratio($ratio) {
  position: relative;
  padding-bottom: $ratio * 100%;

  > * {
    box-sizing: border-box;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.nowrap {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
