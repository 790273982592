[data-view="contact"] {
  .wrapper {
    display: flex;

    .thumbnail {
      width: 160px;
    }
  }

  .address {
    padding: 0 12px;
  }
}
