#navigation {
  align-items: center;
  display: flex;
  font-size: 1.1rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.2em;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.4em;
  }

  .navigation-overlay {
    background-color: rgba(0, 0, 0, .2);
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity 200ms;
    visibility: hidden;
    width: 100%;
  }

  &.offCanvas {
    @include media-breakpoint-down(md) {
      .navigation-overlay {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
      }
    }
  }

  .hamburger {
    border-radius: 50%;
    order: 3;
    line-height: 1;
    z-index: 1;

    .hamburger-box, .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 30px;
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: $colorSubtle;
    }

  }

  .logo {
    font-weight: bold;
    margin-right: auto;
    padding: 15px;
  }

  .menuList {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    .menuList-item {
      border: 1px solid transparent;

      a {
        display: block;
        padding: 12px;
        color: $colorSubtle;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .menu {
      background-color: white;
      box-shadow: -2px 0 9px rgba(0, 0, 0, .15);
      height: 100%;
      overflow: auto;
      padding-left: 15px;
      position: fixed;
      right: 0;
      top: 0;
      transform: translateX(110%);
      transition: transform .33s cubic-bezier(.33, 0, .2, 1);
      width: 240px;

      .menuList {
        margin-top: 70px;
        flex-direction: column;

        .menuList-item {
          &.active {
            border-left-color: $colorSubtle;
          }
        }
      }
    }

    &.offCanvas .menu {
      transform: translateX(0);
    }
  }

  @include media-breakpoint-up(lg) {
    > .logo {
      margin-right: 40px;
    }

    .hamburger {
      display: none;
    }

    .menu {
      position: static;

      .menuList-item {
        &[data-href="home"] {
          display: none;
        }

        &.active {
          border-top-color: $colorSubtle;
        }
      }
    }
  }
}
