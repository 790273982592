// bower:scss
@import "bower_components/bootstrap/scss/bootstrap.scss";
@import "bower_components/slick-carousel/slick/slick.scss";
@import "bower_components/css-hamburgers/_sass/hamburgers/hamburgers.scss";
// endbower
@import "variables";
@import "helpers";
@import "layout";
@import "paperRipple";
@import "spinner";
// Views
@import "view/atelier";
@import "view/biography";
@import "view/collection";
@import "view/contact";
@import "view/event";
@import "view/events";
@import "view/exhibition";
@import "view/home";
@import "view/navigation";

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

img {
  max-width: 100%;
}

body {
  padding-top: 70px;

  &.page-loading {
    #content {
      opacity: 0;
      visibility: hidden;
      transition: none;
    }
  }
}

#content {
  transition: 200ms ease-out;

  > h1 {
    margin-bottom: 10px;
    padding: 0 15px;
    font-size: .9em;
    color: $colorSubtle;
  }

  h2 {
    font-size: 1.2em;
    margin-bottom: 1em;
    margin-top: 3em;
  }
}

.container-fluid {
  max-width: 950px;
  padding-left: 0;
  padding-right: 0;
}

.content-container {
  padding-left: 15px;
  padding-right: 15px;
}

.header {
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  position: fixed;
  transition: box-shadow 200ms;
  z-index: 10;

  &.header-shadow {
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
  }
}

.modal {
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;

    @include media-breakpoint-up(sm) {
      max-width: none;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 0;
    height: auto;
    min-height: 100%;

    > .close {
      border-radius: 50%;
      line-height: 34px;
      position: absolute;
      right: 2px;
      top: 2px;
      text-align: center;
      width: 34px;
      z-index: 1;
    }
  }
}

.gallery {
  opacity: 0;
  transition: opacity 200ms 200ms;

  &.carousel-visible {
    opacity: 1;
  }

  .slick-prev, .slick-next {
    border-radius: 50%;
    font-size: 16px;
    line-height: 50px;
    position: absolute;
    text-align: center;
    bottom: 2px;
    width: 50px;
    z-index: 1;
  }

  .slick-prev {
    left: 2px;
  }

  .slick-next {
    right: 2px;
  }

  .item {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 5vw;
    width: 100vw;

    &.card-visible {
      .item-info {
        opacity: 1;
      }
    }

    .item-image {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      position: relative;

      .image-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        .spinner {
          position: absolute;
          top: calc(50% - 15px);
          left: calc(50% - 15px);
        }
      }

      .galleryImage {
        max-height: 100%;
        opacity: 0;
        transition: opacity 200ms;
      }
    }
  }
}
